import React from "react";
import Icon from "../UI/Icon";

const ArrowLeftIcon = () => {
  return (
    <Icon viewBox="0 0 25 25">
      <path d="M19.5005 11.5H8.33047L13.2105 6.61997C13.6005 6.22997 13.6005 5.58997 13.2105 5.19997C12.8205 4.80997 12.1905 4.80997 11.8005 5.19997L5.21047 11.79C4.82047 12.18 4.82047 12.81 5.21047 13.2L11.8005 19.79C12.1905 20.18 12.8205 20.18 13.2105 19.79C13.6005 19.4 13.6005 18.77 13.2105 18.38L8.33047 13.5H19.5005C20.0505 13.5 20.5005 13.05 20.5005 12.5C20.5005 11.95 20.0505 11.5 19.5005 11.5Z" />
    </Icon>
  );
};

export default ArrowLeftIcon;
