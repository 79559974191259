import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import ArrowLeftIcon from '../components/Icons/ArrowLeftIcon'
import Seo from '../components/Layout/Seo'
import Site from '../components/Layout/Site'
import DefaultVector from '../components/Shared/DefaultVector'
import { Title } from '../components/Shared/SectionHeader.styles'
import Button from '../components/UI/Button'
import Element from '../components/UI/Element'
import { Row, Col, Container } from '../components/UI/Grid'
import { Section } from '../components/UI/Section'
import { Paragraph } from '../components/UI/Typography'

const ThankYou = ({ location }) => {
  return (
    <Site>
      <Seo title="Thank You" />
      <Section defaultVector py={{ _: 16, lg: 0 }}>
        <DefaultVector>
          <Container>
            <Row justifyContent="center" textAlign="center">
              <Col col={{ lg: 6 }}>
                <Element
                  textAlign="center"
                  mb={40}
                  height={{ _: 75, lg: 152 }}
                  width={{ _: 85, lg: 170 }}
                  mx="auto"
                >
                  <StaticImage src="../images/thank-you.png" alt="vector" layout="fullWidth" />
                </Element>
                <Title mb={5}>Thank you{`, ${location?.state?.name}`}!</Title>
                <Paragraph mb={10}>
                  We have received your message and appreciate you reaching out. We'll get back to
                  you soon!
                </Paragraph>
                <Button variant="brand" as={Link} to="/">
                  <ArrowLeftIcon />
                  <Element as="span" ml={2}>
                    Back to Home
                  </Element>
                </Button>
              </Col>
            </Row>
          </Container>
        </DefaultVector>
      </Section>
    </Site>
  )
}

export default ThankYou
